/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@alpinejs/intersect@3.14.8/dist/cdn.min.js
 * - /npm/@alpinejs/mask@3.14.8/dist/cdn.min.js
 * - /npm/@alpinejs/intersect@3.14.8/dist/cdn.min.js
 * - /npm/@alpinejs/persist@3.14.8/dist/cdn.min.js
 * - /npm/@alpinejs/collapse@3.14.8/dist/cdn.min.js
 * - /npm/alpinejs@3.14.8/dist/cdn.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
